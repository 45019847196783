import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../js/components/Layout';
import SEO from '../components/SEO';
import ProjectThumbnail from 'components/ProjectThumbnail/ProjectThumbnail';

export default () => {
  let { allProject: projects } = useStaticQuery(graphql`
    query {
        allProject {
          edges {
            node {
              id
              name
              slug
              description
              priority
              thumbnailUrl
              thumbnailImg {
                childImageSharp {
                  fluid(sizes: """
                    (max-width: 300px) 200px,
                    (max-width: 600px) 400px,
                    (max-width: 900px) 600px,
                    (max-width: 1000px) 800px,
                    1500px""", 
                    quality: 95, fit: COVER
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              project_images {
                url
              }
            }
          }
        }
    }
  `) || [];

  projects = projects.edges.map(p => p.node);
  projects.sort((a, b) => a.priority - b.priority);

  return (
    <Layout>
      <SEO
        title={"Green Culture Studio Landscape Design Portfolio"}
        description={"Explore my portfolio and get ideas for your dream landscape project."}
      />
      <div className="row portfolio-container justify-content-center">
        {
          !projects.length
            ? <h4>Coming soon...</h4>
            : projects.map(
              proj => <ProjectThumbnail key={proj.slug} project={proj} />,
            )
        }
      </div>
    </Layout>
  );
};
