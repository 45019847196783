import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

function ProjectThumbnail(props) {
  const { project } = props;
  const url = `/portfolio/${project.slug}/`;

  return (
    <div className="col-xl-4 col-lg-8 col-md-9 col-sm-10 col-12 px-2 mb-3 project-thumb-container">
      <div className="project-thumb">
        <Link to={url}>
          <div className="project-thumb-info">

            <p className="name project-thumb-name-responsive">{project.name}</p>
            <p className="description project-thumb-description-responsive">{project.description}</p>
          </div>
        </Link>
        {project.thumbnailImg && 
          <Link to={url}>
            <Img
            style={{position: "unset"}}
            fluid={project.thumbnailImg.childImageSharp.fluid}
            fadeIn={false}
            alt={project.name}
          />
          </Link>
        }
      </div>
    </div>
  );
}

export default ProjectThumbnail;
